module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if(!imgpath) var imgpath = ''
if(!title1) var title1 = ''
;
__p += '\r\n\r\n<section class="nybanner">\r\n    <div class="nybanner-bg">\r\n        <b style="background-image: url(' +
((__t = ( imgpath )) == null ? '' : __t) +
');"></b>\r\n    </div>\r\n    <h1>' +
((__t = ( title1 )) == null ? '' : __t) +
'</h1>\r\n</section>';

}
return __p
}