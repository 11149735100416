// 产品部分效果
!(function () {
    if ($('.product-list').length > 0) {
        $(".product-list li").each(function () {
            if ($(this).find('.pro-cont1 .item-img img').length >=3){
                $(this).find('.pro-cont1 .item-img img').eq(1).fadeIn()
                $(this).find('.pro-cont2 .left-list .item').eq(1).addClass('active')
            }else{
                $(this).find('.pro-cont1 .item-img img').eq(0).fadeIn()
                $(this).find('.pro-cont2 .left-list .item').eq(0).addClass('active')
            }
            
        });
        // 产品部分效果，产品角度图切换
        $(".product-list li .pro-cont2 .left-list .item").hover(function (e) {
            e.preventDefault();
            let index = $(this).index()
            $(this).addClass('active').siblings().removeClass('active')
            $(this).parents('li').find('.pro-cont1 .item-img img').eq(index).fadeIn().siblings().fadeOut()
        })

        $(".product-list li .pro-cont2 .left-list").mouseleave(function () {
            if ($(this).parents('li').find('.pro-cont1 .item-img img').length >= 3){
                $(this).parents('li').find('.pro-cont2 .left-list .item').eq(1).addClass('active').siblings().removeClass('active')
                $(this).parents('li').find('.pro-cont1 .item-img img').eq(1).fadeIn().siblings().fadeOut()
            }else{
                $(this).parents('li').find('.pro-cont2 .left-list .item').eq(0).addClass('active').siblings().removeClass('active')
                $(this).parents('li').find('.pro-cont1 .item-img img').eq(0).fadeIn().siblings().fadeOut()
            }
        })
    }
})()


// 内页
// $(document).ready(function () {
if (window.location.hash == '#nav-md') {
        $('.header').addClass('hide')
        // var scTop = 0
        // if ($('.common-nav').length > 0) {
        //     scTop = $('.common-nav').offset().top
        // }
        // console.log(scTop)

        // $('html, body').scrollTop(scTop)
    }
// });