import './index.scss'


$('.footer-top .back-top').on('click', function () {
    if ($('.nybanner').length > 0) {
        $("html, body").animate({
            scrollTop: $('.nybanner').offset().top + $('.nybanner').height() - $('.header').height() + "px"
        }, {
            duration: 500,
            easing: "swing"
        });
        return false;
    } else {
        $("html, body").animate({
            scrollTop: 0 + "px"
        }, {
            duration: 500,
            easing: "swing"
        });
        return false;
    }
})