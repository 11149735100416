module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="footer-top">\r\n        <div class="container">\r\n            <div class="left-img">\r\n                <img src="' +
((__t = (require('./images/footer_img.png'))) == null ? '' : __t) +
'" alt="">\r\n            </div>\r\n            <div class="right-text">\r\n                <div class="contact">\r\n                    <div class="info-title">JINYU Tire Group</div>\r\n                    <p>Group Add.: Floor 10, Block A, Platinum Plaza, No. 5 Wenling Road, Laoshan District, Qingdao, China.</p>\r\n                    <p>Tel : <a href="tel:+0086-400-600-7809">0086-400-600-7809</a></p>\r\n                    <p>E-mail : <a href="mailto:Europe.office@jinyutyres.com">Europe.office@jinyutyres.com</a></p>\r\n                </div>\r\n                <div class="link">\r\n                    <div class="info-title">FIND US ON</div>\r\n                    <div class="cont">\r\n                        <a href="#" target="_blank"><i class="iconfont icon-facebook-fill"></i></a>\r\n                        <a href="#" target="_blank"><i class="iconfont icon-linkedin-fill"></i></a>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n        <div class="back-top"><i class="iconfont icon-jiantou_you"></i></div>\r\n    </div>\r\n    <div class="footer-copyright">\r\n        <div class="container">\r\n            <div class="left">Copyright © JINYU Tire Group. All Rights Reserved.</div>\r\n            <div class="right">\r\n                <a href="#">Impressum</a><span>|</span>\r\n                <a href="#">AGB</a><span class="site-map">|</span>\r\n                <a href="javascript:void(0);" data-open="map" class="site-map">SiteMap</a>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</footer>';

}
return __p
}