import './index.scss'

// var beforeScroH = $(document).scrollTop();
$(document).scroll(function () { 
    var scroH = $(document).scrollTop();     
    var viewH = $(window).height();   
    var contentH = $(document).height();     
    $('.header').removeClass('hide')
    if (scroH > 150) {
        $('header .header').addClass('tran')
        $('header .header-right').addClass('active');
    } else {
        $('header .header').removeClass('tran')
        $('header .header-right').removeClass('active')
    }
    //判断页面是上滚还是下滚
    // var delta = scroH - beforeScroH;
    // if (delta > 0) {
    //     if (scroH > 300) {
    //         $('header .header').addClass('tran')
    //     }
    // } else if (delta < 0) {
    //     $('header .header').removeClass('tran')
    // }
    // beforeScroH = scroH;         
});

$('[data-open="cmenu"]').on('click', function () {  
    if ($('header .hamburger').hasClass('active')) {
        $('header .hamburger').removeClass('active')
        $('header .open-menu').removeClass('active')
        $('header .mob-nav').removeClass('active')
    } else {
        $('header .hamburger').addClass('active')
        $('header .open-menu').addClass('active')
        $('header .mob-nav').addClass('active')
    }
})

$('header .mob-nav').mouseleave(function(){
    $('header .hamburger').removeClass('active')
    $('header .open-menu').removeClass('active')
    $('header .mob-nav').removeClass('active')
})

// $(".header .pc-nav ul li").mouseenter(function () {
//     if (!$(this).hasClass('active')) {
//         $(this).addClass('hover');
//         $(this).siblings('.active').addClass('not-active');
//     }
// }).mouseleave(function () {
//     if (!$(this).hasClass('active')) {
//         $('.header .pc-nav ul li').removeClass('hover');
//         $('.header .pc-nav ul li.active').removeClass('not-active');
//     }
// });